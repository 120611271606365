<template>
  <main class="intro">
    <div class="top_cont">
      <img src="~assets/image/main/intro_top.png" alt="" class="pc" />
      <img src="~assets/image/main/intro_top_m.png" alt="" class="m" />
      <div class="txt_box">
        <p class="t1">WHAT IS</p>
        <p class="t2">HOGOO 153</p>
      </div>
    </div>
    <div class="sub_mid">
      <div class="cont __no1">
        <div class="in_cont">
          <p class="t1">구인구직의 패러다임을 바꿀 떠오르는 플랫폼</p>
          <p class="t2">
            호구 153은 호텔관련 구인과 구직을 매칭서비스로 제공하는 회사입니다.
            <br />
            모두가 호구153을 통하여 원하는날, 원하는 시간대 , <br class="m" />
            원하는 일을 하며경제적인 만족을 누릴 수 있게 만들고자 합니다.
          </p>
        </div>
      </div>
      <div class="cont __no2">
        <div class="in_cont">
          <ul>
            <li>
              <strong>mission</strong>
              <p>
                구인 업체에게는 필요한 사람을 <br />구직자에게는 맞춤형 일자리
                제공
              </p>
            </li>
            <li>
              <strong>core value</strong>
              <p>
                고객 신뢰 , 변화와 혁신 또 <br class="m" />
                구인자 , 구직자 , 협력사 및 <br />
                직원들간의 소통과 협력을 통해 <br class="m" />
                시너지 효과를 창출
              </p>
            </li>
            <li>
              <strong>vision</strong>
              <p>
                창의적인 사고와 혁신 정신으로 <br class="m" />
                구인 ,<br class="pc" />
                구직자들의 새로운 미래 <br class="m" />
                가치를 창출
              </p>
            </li>
          </ul>
          <img src="~assets/image/main/sub_mid_cont02_img.png" alt="" />
        </div>
      </div>
      <div class="cont __no3">
        <div class="in_cont">
          <img src="~assets/image/main/sub_mid_cont03_img.png" alt="" />
          <div class="text_box">
            <p class="t1">
              안녕하십니까 ! <br />
              hogoo153 대표 <br class="pc" />한윤덕입니다.
            </p>
            <p class="t2">
              20년간 호텔 용역관리를 해오면서 사람을 구하는데 참 많은 어려움과
              스트레스가 있었습니다. <br />
              누군가가 필요로할 때 좋은 사람들을 공급해주면 좋겠다. 라는 생각을
              갖고 있다가,<br class="pc" />
              호구153이라는 플랫폼을 만들게 되었습니다. <br />
              플랫폼 서비스 확산을 통해 국내 호텔업 고용시장의 변화를 주도하며
              <br class="pc" />
              고용 플랫폼의 대표 브랜드로 성장 시키겠습니다 !
            </p>
          </div>
        </div>
      </div>
      <div class="cont __no4">
        <div class="in_cont">
          <p class="tit">management philosophy</p>
          <img
            src="~assets/image/main/sub_mid_cont04_img.png"
            alt=""
            class="img1"
          />
          <img
            src="~assets/image/main/sub_mid_cont04_img2.png"
            alt=""
            class="img2"
          />
          <div class="text_box">
            <p class="t1">신속하고 정확하게</p>
            <p class="t2">
              구인/구직 정보를 신속하게 등록하고, 구직자의 신속한 확인과
              <br class="m" />
              응답을 통하여 <br class="pc" />
              정확한 서비스를 공급할 것 입니다.
            </p>
          </div>
          <div class="text_box">
            <p class="t1">품위있고 깨끗하게</p>
            <p class="t2">
              구직자는 호구153 아카데미 교육을 통해 당사의 호텔 객실정비,
              <br class="m" />
              객실청소 <br class="pc" />
              서비스를 품위있고 깨끗하게 제공할 것 입니다.
            </p>
          </div>
          <div class="text_box">
            <p class="t1">넓게보고 깊게보고</p>
            <p class="t2">
              숙박업종, 외식 사업을 포함한 모든 식/음료 서비스 , 홈-크리닝
              <br class="m" />서비스, <br class="pc" />
              돌봄 서비스까지 향후 국내외 를 막라한 다양한 인력수급을
              <br class="m" />선두하는 <br class="pc" />
              플랫폼이 될 것입니다.
            </p>
          </div>
          <div class="text_box">
            <p class="t1">편안하고 행복하게</p>
            <p class="t2">
              호구153에 가입하여 본인이 원하는 날 원하는 시간대에 일을
              <br class="m" />하면서 <br class="pc" />
              경제적인 만족을 누릴 수 있을것으로 기대합니다.
            </p>
          </div>
        </div>
      </div>
      <div class="cont __no5">
        <div class="in_cont">
          <p class="tit">Brand Logo</p>
          <div class="brand_box logo">
            <div class="img_box">
              <img src="~assets/image/main/sub_mid_cont05_img.png" alt="" />
            </div>
            <div class="txt_box">
              <p class="t1">Keyword</p>
              <p class="t2">신뢰, 믿음, 신속, 서비스, 청결, 상생</p>
              <p class="t1">Concept</p>
              <p class="t2">여성스러움, 심플, 깔끔, 부드러움</p>
              <p class="t1">Color</p>
              <ul>
                <li>
                  <span></span>
                  <p>#ef7c4a</p>
                </li>
                <li>
                  <span></span>
                  <p>#33718f</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="in_cont">
          <p class="tit">Brand Character</p>
          <div class="brand_box character">
            <div class="img_box">
              <img src="~assets/image/main/sub_mid_cont06_img.png" alt="" />
            </div>
            <div class="txt_box">
              <p class="t1"><strong>HOGOO</strong> <span>(호구)</span></p>
              <p class="t2">
                호구153 브랜드 철학인 신뢰와 상생에 대한 개념을 따뜻하면서
                <br />
                친근한 이미지로 잘 전달 해주는 “호구” 입니다.<br />
                귀여우면서 강직한 모습이 인상적인 “호구”는 많은 사람에게<br />
                꿈과 희망을 전달하며 다양한 활동을 할 것입니다.
              </p>
              <p class="t1">Color</p>
              <ul>
                <li>
                  <span></span>
                  <p>#ef7c4a</p>
                </li>
                <li>
                  <span></span>
                  <p>#3f3a39</p>
                </li>
                <li>
                  <span></span>
                  <p>#ecbdb0</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {}
</script>

<style></style>
